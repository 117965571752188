<template>
  <div>
    <InterAccountTransfersForm
      :value="transaction"
      @passBack="$emit('passBack', $event)"
    />
  </div>
</template>
<script>
export default {
  name: "InterAccountTransfersWrapper",
  props: ["transaction"],
  components: {
    InterAccountTransfersForm: () => import("./InterAccountTransfersForm.vue"),
  },
  data() {
    return {
      actionBtn: {
        name: "CreateInterAccountTransfersBtn",
        path: "./InterAccountTransfers/",
      },
    };
  },
};
</script>